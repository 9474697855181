import React from 'react';

import { PersonvelgerDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';

import PersonvelgerItem, { Person } from './personliste-item';
import Messages, { PersonvelgerErrors, PersonvelgerStatusMessages } from '../messages';
import MessagesV2 from '../messages-v2';

import styles from './styles.module.scss';

interface PersonListeProps {
  children: React.ReactNode;
  /** Tekster */
  resources: PersonvelgerDTO;
  /** Liste med brukere i personvelgeren */
  users?: Person[];
  /** Callback når man har klikket på en person i personvelgeren */
  onSelectUser: (user: Person) => void;
  /** Toggle visning av ulike statusmeldinger i personvelgeren */
  messages?: PersonvelgerStatusMessages | null;
  /** Feilmeldinger */
  errors?: PersonvelgerErrors;
  /** Uthev brukeren som er valgt */
  highlightUser?: boolean;
}

const PersonListe: React.FC<PersonListeProps> = props => {
  return (
    <>
      {getFeatureToggle('NyForside2025') ? (
        <MessagesV2 resources={props.resources} messages={props.messages} errors={props.errors}>
          {props.children}
        </MessagesV2>
      ) : (
        <Messages resources={props.resources} messages={props.messages} errors={props.errors}>
          {props.children}
        </Messages>
      )}

      <ul className={styles.personliste}>
        {props.users?.map(user => (
          <PersonvelgerItem
            key={user.profilGuid}
            user={user}
            highlight={props.highlightUser}
            onSelectUser={() => props.onSelectUser(user)}
          />
        ))}
      </ul>
    </>
  );
};

export default PersonListe;
