import React from 'react';

import AnchorLink, { AnchorLinkTargets } from '@helsenorge/designsystem-react/components/AnchorLink';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';

import { VarselDTO } from '@helsenorge/framework-utils/types/entities/cms.v1entities';

import { PersonvelgerProps } from '../messages';
import styles from '../messages/styles.module.scss';

const hasValidLink = (varsel: VarselDTO): boolean => !!varsel?.link.href && !!varsel?.link.text;

interface VarselProps {
  varsel: VarselDTO;
}

const Varsel: React.FC<VarselProps> = props => {
  return (
    <NotificationPanel variant={'info'}>
      {props.varsel.introduction}
      {hasValidLink(props.varsel) && (
        <>
          <Spacer size="s" />
          <AnchorLink href={props.varsel.link.text} target={props.varsel.link.target as AnchorLinkTargets}>
            {props.varsel.link.text}
          </AnchorLink>
        </>
      )}
    </NotificationPanel>
  );
};

const Messages: React.FC<PersonvelgerProps> = ({ errors, resources, messages, children }) => {
  const showBarn1116PlusVarsel = (messages?.harBarnOver11 || messages?.harBarnOver16) && resources.representasjonerBarn16PlusVarsel;
  const showManglendeForeldreansvarVarsel =
    messages?.harBarnUnder16UtenForeldreansvar && resources.representasjonerManglendeForeldreansvarVarsel;
  const showHarBarnMedAnnenAdresse = messages?.harBarnMedAnnenAdresse && resources.representasjonerBarnAnnenAdresseVarsel;

  return (
    <>
      <div className={styles.messages}>
        {errors?.representasjonsforholdError && resources?.errorRepresentasjoner && (
          <NotificationPanel variant={'error'}>{resources?.errorRepresentasjoner}</NotificationPanel>
        )}

        {showBarn1116PlusVarsel && <Varsel varsel={resources.representasjonerBarn16PlusVarsel} />}

        {showManglendeForeldreansvarVarsel ? (
          <Varsel varsel={resources.representasjonerManglendeForeldreansvarVarsel} />
        ) : (
          showHarBarnMedAnnenAdresse && <Varsel varsel={resources.representasjonerBarnAnnenAdresseVarsel} />
        )}

        {errors?.profileError && resources?.errorValgtPerson && (
          <NotificationPanel variant={'error'}>{resources?.errorValgtPerson}</NotificationPanel>
        )}

        {children}
      </div>
    </>
  );
};

export default Messages;
